import { useMemo } from "react"
import { differenceInHours } from "date-fns"
import { SelectOption } from "@/design-system/Select"

/**
 * Filters options to only include those that have start time after minTime
 */
export const useValidTimeRangeOptions = <T extends string>({
  options,
  minTime,
  minTimeLabel,
  maxValue,
}: {
  options: readonly SelectOption<T>[]
  minTime: Date | undefined
  minTimeLabel: string
  maxValue?: number // In months
}): SelectOption<T | string>[] => {
  return useMemo(() => {
    // No minTime supplied so assume it is loading and no options are valid yet
    if (!minTime) {
      return []
    }

    const hoursSinceMin = differenceInHours(new Date(), minTime, {
      roundingMethod: "ceil",
    })

    let validFixedOptions: SelectOption<T | string>[] = options.slice()

    // Filter options where start would precede minTime
    validFixedOptions = validFixedOptions.filter(
      option => hoursSinceMin > Number(option.value),
    )

    // Add minTime option
    validFixedOptions = [
      ...validFixedOptions,
      {
        label: minTimeLabel,
        value: hoursSinceMin.toString(),
      },
    ]

    // Filter options where option value exceeds maxValue
    if (maxValue !== undefined) {
      validFixedOptions = validFixedOptions.filter(
        option => Number(option.value) <= maxValue,
      )
    }

    return validFixedOptions
  }, [minTime, options, minTimeLabel, maxValue])
}
