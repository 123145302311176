import { useMemo } from "react"
import { startOfMinute, startOfHour, startOfDay } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import { BucketSize } from "@/lib/graphql/__generated__/CollectionPriceHistoryChartQuery.graphql"

type UsePriceHistoryChartAdjustedStartTimeOptions = {
  bucketSize: BucketSize
  startDate: Date | undefined
}

export const usePriceHistoryChartAdjustedStartTime = ({
  bucketSize,
  startDate,
}: UsePriceHistoryChartAdjustedStartTimeOptions) => {
  return useMemo(() => {
    if (!startDate) {
      return undefined
    }
    // Round to the start of the bucketSize to include entire first bucket
    // First bucket has some points within the range that must be included (along with rest of bucket)
    switch (bucketSize) {
      case "MINUTE":
        return startOfMinute(startDate)
      case "HOUR":
        return startOfHour(startDate)
      default: {
        const dayStartZoned = startOfDay(startDate)
        return zonedTimeToUtc(dayStartZoned, "UTC")
      }
    }
  }, [bucketSize, startDate])
}
