import React, { CSSProperties } from "react"
import { CenterAligned, Icon, Text } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"

type ChartNoDataInTimeRangeProps = {
  height?: CSSProperties["height"]
}

export const ChartNoDataInTimeRange = ({
  height,
}: ChartNoDataInTimeRangeProps) => {
  const t = useTranslate("collection")
  return (
    <CenterAligned className="p-8" style={{ height }}>
      <Block className="max-w-[440px] text-center text-secondary">
        <Icon className="mb-8" size={32} value="schedule" />
        <Text.Body asChild size="small" weight="semibold">
          <div>
            {t("chartNoDataInTimeRange.title", "No events in this time range")}
          </div>
        </Text.Body>
        <Text.Body asChild size="small">
          <div>
            {t(
              "chartNoDataInTimeRange.body",
              "Try a different time range or check back later.",
            )}
          </div>
        </Text.Body>
      </Block>
    </CenterAligned>
  )
}
