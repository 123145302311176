const decodePointName = (pointName: string) => {
  const nameComponents = pointName.split(":").map(Number)
  return { seriesIndex: nameComponents[0], pointIndex: nameComponents[1] }
}

export const getPointIndex = (point: Highcharts.Point) => {
  if (point.name) {
    return decodePointName(point.name).pointIndex
  }
  return point.index
}

export const getSeriesIndex = (point: Highcharts.Point) => {
  if (point.name) {
    return decodePointName(point.name).seriesIndex
  }
  return point.series.index
}
