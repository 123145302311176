import React, { createContext, useCallback, useContext, useState } from "react"
import { noop } from "lodash"

type Range = [number | undefined, number | undefined]

type ChartContextType = {
  isZoomed: boolean
  isPanning: boolean
  hoverPoints: Highcharts.Point[]
  hoveredPointUrl: string | undefined
  resetZoom: () => void
  reflow: () => void
  setChart: React.Dispatch<React.SetStateAction<Highcharts.Chart | undefined>>
  setIsZoomed: React.Dispatch<React.SetStateAction<boolean>>
  setIsPanning: React.Dispatch<React.SetStateAction<boolean>>
  setXAxisRange: React.Dispatch<React.SetStateAction<Range | undefined>>
  setHoverPoints: React.Dispatch<React.SetStateAction<Highcharts.Point[]>>
  setHoveredPointUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ChartContext = createContext<ChartContextType>({
  isZoomed: false,
  isPanning: false,
  hoverPoints: [],
  hoveredPointUrl: undefined,
  resetZoom: noop,
  reflow: noop,
  setChart: noop,
  setIsZoomed: noop,
  setIsPanning: noop,
  setXAxisRange: noop,
  setHoverPoints: noop,
  setHoveredPointUrl: noop,
})

export const useChart = () => useContext(ChartContext)

type ChartContextProviderProps = {
  children: React.ReactNode
}

export const ChartContextProvider = ({
  children,
}: ChartContextProviderProps) => {
  const [chart, setChart] = useState<Highcharts.Chart>()
  const [isZoomed, setIsZoomed] = useState(false)
  const [isPanning, setIsPanning] = useState(false)
  const [xAxisRange, setXAxisRange] = useState<Range>()
  const [hoverPoints, setHoverPoints] = useState<Highcharts.Point[]>([])
  const [hoveredPointUrl, setHoveredPointUrl] = useState<string>()

  const resetZoom = useCallback(() => {
    if (xAxisRange) {
      chart?.xAxis[0].setExtremes(...xAxisRange)
      // reset to default y-axis range since we only support fixed ranges for x-axis
      chart?.yAxis[0].setExtremes(undefined, undefined)
    } else {
      chart?.zoomOut()
    }
  }, [chart, xAxisRange])

  const reflow = useCallback(() => {
    chart?.reflow()
  }, [chart])

  return (
    <ChartContext.Provider
      value={{
        isZoomed,
        isPanning,
        hoverPoints,
        hoveredPointUrl,
        resetZoom,
        reflow,
        setChart,
        setIsZoomed,
        setIsPanning,
        setXAxisRange,
        setHoverPoints,
        setHoveredPointUrl,
      }}
    >
      {children}
    </ChartContext.Provider>
  )
}
