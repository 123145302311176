type ZoomToOptions = {
  zoomStart: number
  zoomEnd: number
  chart: Highcharts.Chart
}

export const zoomTo = ({ zoomStart, zoomEnd, chart }: ZoomToOptions) => {
  const xAxis = chart.xAxis[0]
  xAxis.setExtremes(
    zoomStart,
    xAxis.max !== null ? Math.min(xAxis.max, zoomEnd) : zoomEnd,
  )
  chart.showResetZoom()
}
