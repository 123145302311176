import React, { useCallback, useMemo } from "react"
import { Text } from "@opensea/ui-kit"
import {
  differenceInDays,
  format,
  isThisHour,
  startOfDay,
  startOfHour,
  startOfMinute,
  differenceInMinutes,
  differenceInHours,
  isThisMinute,
} from "date-fns"
import { graphql, useFragment } from "react-relay"
import { ChartNoData } from "@/components/analytics/ChartNoData.react"
import { ChartNoDataInTimeRange } from "@/components/analytics/ChartNoDataInTimeRange.react"
import { SHORT_MONTH_DATETIME_FORMAT_STRING } from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import {
  TimeSeriesChart,
  TimeSeriesChartProps,
} from "@/design-system/charts/TimeSeriesChart"
import { useTranslate } from "@/hooks/useTranslate"
import { BucketSize } from "@/lib/graphql/__generated__/CollectionPriceHistoryChartQuery.graphql"
import {
  PriceHistoryChart_data$data,
  PriceHistoryChart_data$key,
} from "@/lib/graphql/__generated__/PriceHistoryChart_data.graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import {
  MAX_DISPLAYED_DECIMAL_PLACES,
  bn,
  display,
  roundAboveMin,
} from "@/lib/helpers/numberUtils"

type VolumeDataPoint = PriceHistoryChart_data$data["results"][number]

const getAveragePrice = (dp: VolumeDataPoint) =>
  bn(dp.volume.quantity, dp.volume.asset.decimals)
    .div(bn(dp.quantity, 0))
    .toNumber()

const getVolume = (dp: VolumeDataPoint) =>
  bn(dp.volume.quantity, dp.volume.asset.decimals).toNumber()

type PriceHistoryTooltipProps = {
  dp: VolumeDataPoint
  getDate: (dp: VolumeDataPoint) => Date
  dateFormat: string
}

const PriceHistoryTooltip = ({
  dp,
  getDate,
  dateFormat = SHORT_MONTH_DATETIME_FORMAT_STRING,
}: PriceHistoryTooltipProps) => {
  const t = useTranslate("common")

  const averagePrice = bn(getAveragePrice(dp))
  const volume = bn(dp.volume.quantity, dp.volume.asset.decimals)

  return (
    <Block className="relative text-center">
      <Text.Body className="block" size="medium" weight="semibold">
        {t("priceHistory.tooltip.volume", "{{volume}} ETH", {
          volume: roundAboveMin(
            volume.isGreaterThan(100) ? volume.integerValue() : volume,
            MAX_DISPLAYED_DECIMAL_PLACES,
          ),
        })}
      </Text.Body>
      <Text.Body className="text-secondary" size="small">
        {t(
          "priceHistory.tooltip.averagePrice",
          "Avg. price: {{avgPrice}} ETH",
          {
            avgPrice: roundAboveMin(
              averagePrice.isGreaterThan(100)
                ? averagePrice.integerValue()
                : averagePrice,
              MAX_DISPLAYED_DECIMAL_PLACES,
            ),
          },
        )}
      </Text.Body>
      <Text.Body className="block text-secondary" size="small">
        {t("priceHistory.tooltip.numSales", "Num. sales: {{numSales}}", {
          numSales: display(dp.quantity),
        })}
      </Text.Body>
      <Text.Body className="block text-secondary" size="small">
        {format(getDate(dp), dateFormat)}
      </Text.Body>
    </Block>
  )
}

export type PriceHistoryChartProps = Pick<
  TimeSeriesChartProps<VolumeDataPoint>,
  "height" | "interactive"
> & {
  startDate?: Date
  endAtPresent?: boolean
  data: PriceHistoryChart_data$key
  bucketSize?: BucketSize
  showNoData?: boolean
}

export const PriceHistoryChart = ({
  data: dataKey,
  height,
  startDate,
  endAtPresent,
  bucketSize = "DAY",
  interactive = true,
  showNoData = true,
}: PriceHistoryChartProps) => {
  const { results: data } = useFragment(
    graphql`
      fragment PriceHistoryChart_data on TradeHistoryType {
        results {
          bucketStart
          quantity
          volume {
            asset {
              decimals
            }
            quantity
          }
        }
      }
    `,
    dataKey,
  )

  const getDate = useCallback(
    (dp: VolumeDataPoint) => dateFromISO8601(dp.bucketStart),
    [],
  )

  const renderPriceHistoryTooltip = useCallback(
    (dp: VolumeDataPoint) => (
      <PriceHistoryTooltip
        dateFormat={SHORT_MONTH_DATETIME_FORMAT_STRING}
        dp={dp}
        getDate={getDate}
      />
    ),
    [getDate],
  )

  const rangeEnd = useMemo(() => {
    const now = new Date()
    switch (bucketSize) {
      case "MINUTE":
        return startOfMinute(now)
      case "HOUR":
        return startOfHour(now)
      default:
        return startOfDay(now)
    }
  }, [bucketSize])

  if (data.length === 0) {
    if (!showNoData) {
      return null
    }
    return startDate ? (
      <ChartNoDataInTimeRange height={height || undefined} />
    ) : (
      <ChartNoData height={height || undefined} />
    )
  }

  const lastDataPoint = data[data.length - 1]
  const isLastDataPointComplete =
    bucketSize === "MINUTE"
      ? !isThisMinute(new Date(lastDataPoint.bucketStart))
      : bucketSize === "HOUR"
      ? !isThisHour(new Date(lastDataPoint.bucketStart))
      : bucketSize === "DAY"
      ? differenceInHours(
          rangeEnd,
          dateFromISO8601(lastDataPoint.bucketStart),
        ) > 24
      : differenceInDays(rangeEnd, dateFromISO8601(lastDataPoint.bucketStart)) >
        7 // MONTH bucket size is not supported

  return (
    <TimeSeriesChart
      columnAxisTitle="Volume (ETH)"
      data={data}
      getColumnValue={getVolume}
      getDate={getDate}
      getValue={getAveragePrice}
      height={height}
      interactive={interactive}
      isLastDataPointComplete={isLastDataPointComplete}
      lineType="line"
      rangeEnd={endAtPresent ? rangeEnd : undefined}
      rangeStart={startDate}
      renderTooltip={renderPriceHistoryTooltip}
      yAxisTitle="Average price (ETH)"
    />
  )
}

const DEFAULT_NUM_COLUMNS = 7

type PriceHistoryChartSkeletonProps = Pick<
  PriceHistoryChartProps,
  "bucketSize" | "startDate" | "height"
>

export const PriceHistoryChartSkeleton = ({
  bucketSize,
  startDate,
  height,
}: PriceHistoryChartSkeletonProps) => {
  let numColumns = DEFAULT_NUM_COLUMNS
  let columnWidth: string | undefined = undefined
  switch (bucketSize) {
    case "MINUTE":
      numColumns = startDate
        ? differenceInMinutes(new Date(), startDate)
        : DEFAULT_NUM_COLUMNS
      columnWidth = "4px"
      break
    case "HOUR":
      numColumns = 24
      columnWidth = "16px"
      break
    case "DAY":
      numColumns = startDate
        ? differenceInDays(new Date(), startDate)
        : DEFAULT_NUM_COLUMNS
      columnWidth = numColumns > 16 ? "16px" : undefined
      break
    default:
      numColumns = DEFAULT_NUM_COLUMNS
      break
  }

  // padding to account for axis and axis titles
  return (
    <TimeSeriesChart.Skeleton
      columnWidth={columnWidth}
      height={height}
      numColumns={numColumns}
    />
  )
}
