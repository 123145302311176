import { Theme } from "@/styles/styled"
import THEMES from "@/styles/themes"
import { DEFAULT_DATE_TIME_LABEL_FORMATS } from "./constants"

export const DEFAULT_HIGHCHART_OPTIONS: Highcharts.Options = {
  title: {
    text: "",
  },
  credits: {
    enabled: false, // Disable highcharts.com watermark
  },
  time: {
    useUTC: false,
  },
  boost: {
    useGPUTranslations: true,
  },
}

export const INTERACTIVE_CHART_OPTIONS: Highcharts.ChartOptions = {
  zooming: {
    type: "x",
    pinchType: "x",
  },
  panKey: "shift",
  panning: {
    enabled: true,
  },
}

type CreateChartOptions = Highcharts.ChartOptions & {
  interactive?: boolean
  allowZoom?: boolean
}

export const createChartOptions = (
  theme: Theme,
  {
    interactive = false,
    allowZoom = true,
    zooming,
    ...overrides
  }: CreateChartOptions = {},
): Highcharts.ChartOptions => ({
  spacingLeft: 4,
  spacingRight: 4,
  spacingTop: 8,
  spacingBottom: 8,
  backgroundColor: "", // transparent
  style: {
    fontFamily: "'Inter', sans-serif",
    color: THEMES[theme].colors.text.primary,
  },
  borderWidth: 0,
  ...(interactive ? INTERACTIVE_CHART_OPTIONS : {}),
  zooming: {
    ...zooming,
    ...(interactive ? INTERACTIVE_CHART_OPTIONS.zooming : {}),
    type: interactive && allowZoom ? zooming?.type ?? "x" : undefined,
    resetButton: {
      theme: {
        style: {
          display: "none", // always hide default reset zoom button
        },
      },
    },
  },
  ...overrides,
})

type CreateTooltipOptions = Highcharts.TooltipOptions

export const createTooltipOptions = (
  _theme: Theme,
  { ...overrides }: CreateTooltipOptions = {},
): Highcharts.TooltipOptions => ({
  // Options - Enable tooltip (for shared behaviour) but hide it
  enabled: true,
  shared: true,
  style: {
    display: "none",
  },
  followTouchMove: true,
  // Overrides
  ...overrides,
})

type CreateXAxisOptions = Highcharts.XAxisOptions & {
  axisTitle?: string
}

export const createXAxisOptions = (
  theme: Theme,
  { axisTitle, ...overrides }: CreateXAxisOptions = {},
): Highcharts.XAxisOptions => ({
  crosshair: true,
  lineColor: THEMES[theme].colors.components.border.level2,
  tickColor: THEMES[theme].colors.components.border.level2,
  dateTimeLabelFormats: DEFAULT_DATE_TIME_LABEL_FORMATS,
  ...overrides,
  title: {
    text: axisTitle ?? null,
    style: {
      fontWeight: "600",
      color: THEMES[theme].colors.text.primary,
      fontSize: "14px",
    },
    margin: 16,
    ...overrides.title,
  },
  labels: {
    style: {
      fontSize: "14px",
      color: THEMES[theme].colors.text.secondary,
      ...overrides.labels?.style,
    },
    ...overrides.labels,
  },
})

type CreateYAxisOptions = Highcharts.YAxisOptions & {
  axisTitle?: string
}

export const createYAxisOptions = (
  theme: Theme,
  { axisTitle, ...overrides }: CreateYAxisOptions = {},
): Highcharts.YAxisOptions => ({
  gridLineColor: THEMES[theme].colors.components.border.level2,
  ...overrides,
  title: {
    text: axisTitle ?? null,
    style: {
      fontWeight: "600",
      color: THEMES[theme].colors.text.primary,
      fontSize: "14px",
    },
    margin: 16,
    ...overrides.title,
  },
  labels: {
    style: {
      fontSize: "14px",
      color: THEMES[theme].colors.text.secondary,
      ...overrides.labels?.style,
    },
    ...overrides.labels,
  },
})

type CreateSeriesOptions = Highcharts.SeriesOptionsType

export const createSeriesOptions = (
  theme: Theme,
  overrides: CreateSeriesOptions,
): Highcharts.SeriesOptionsType => {
  const options = {
    // options
    showInLegend: false,
    // styles
    color: THEMES[theme].colors.primary,
    borderColor: THEMES[theme].colors.components.border.level2,
    ...overrides,
  }
  return options
}

type CreateLegendOptions = Highcharts.LegendOptions

export const createLegendOptions = (
  theme: Theme,
  overrides: CreateLegendOptions,
): Highcharts.LegendOptions => {
  return {
    itemStyle: {
      fontSize: "14px",
      fontWeight: "600",
      color: THEMES[theme].colors.text.primary,
    },
    itemHoverStyle: {
      color:
        theme === "light"
          ? THEMES[theme].colors.charcoal
          : THEMES[theme].colors.white,
    },
    ...overrides,
  }
}
