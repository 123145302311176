import React, { CSSProperties } from "react"
import { CenterAligned, Icon, Text, classNames } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"

type ChartNoDataProps = {
  height?: CSSProperties["height"]
  className?: string
}

export const ChartNoData = ({ className, height }: ChartNoDataProps) => {
  const t = useTranslate("collection")
  return (
    <CenterAligned className={classNames("p-8", className)} style={{ height }}>
      <Block className="max-w-[440px] text-center text-secondary">
        <Icon className="mb-8" size={32} value="schedule" />
        <Text.Body asChild size="small" weight="semibold">
          <div>{t("chartNoData.title", "No events have occurred yet")}</div>
        </Text.Body>
        <Text.Body asChild size="small">
          <div>{t("chartNoData.body", "Check back later.")}</div>
        </Text.Body>
      </Block>
    </CenterAligned>
  )
}
