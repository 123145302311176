import { useUpdateEffect } from "react-use"
import { useMouseClick } from "./useMouseClick"

export function useMouseUp<T extends Element>(callback: () => unknown) {
  const [isMouseClicked, ref] = useMouseClick<T>()

  useUpdateEffect(() => {
    if (!isMouseClicked) {
      callback()
    }
  }, [isMouseClicked])

  return ref
}
