import React from "react"
import {
  SpaceBetween,
  SpaceBetweenProps,
  Text,
  classNames,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Block, type BlockProps } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"
import { ChartContextProvider, useChart } from "./ChartContext.react"

const ChartPanelBase = ({ children, ...props }: BlockProps) => {
  return (
    <ChartContextProvider>
      <ChartPanelContainer {...props}>{children}</ChartPanelContainer>
    </ChartContextProvider>
  )
}

const ChartPanelContainer = styled(Block).attrs({
  as: "figure",
})<BlockProps>`
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  margin: 0;
  display: flex;
  flex-direction: column;

  ${media({
    md: css`
      border: 1px solid ${({ theme }) => theme.colors.components.border.level2};
    `,
  })}
`
ChartPanelContainer.defaultProps = {
  padding: { _: "32px 0 0 0", md: "32px" },
}

const ChartPanelTitle = styled(Text.Body)
  .attrs({ as: "figcaption" })
  .attrs({ size: "medium", weight: "semibold" })``

const ChartPanelHeader = ({ className, ...props }: SpaceBetweenProps) => (
  <SpaceBetween
    className={classNames("h-14 items-start", className)}
    {...props}
  />
)

const ChartPanelResetZoom = () => {
  const t = useTranslate("collection")
  const { isZoomed, resetZoom } = useChart()

  // if not zoomed then don't show the reset button
  if (!isZoomed) {
    return <></>
  }

  return (
    <Button size="small" variant="secondary" onClick={resetZoom}>
      <Text.Body size="small" weight="semibold">
        {t("chartPanel.resetZoom.cta", "Reset")}
      </Text.Body>
    </Button>
  )
}

export const ChartPanel = Object.assign(ChartPanelBase, {
  Header: ChartPanelHeader,
  Title: ChartPanelTitle,
  ResetZoom: ChartPanelResetZoom,
})
