export const DEFAULT_MARKER_TOOLTIP_POSITIONER_OFFSET = {
  x: 13.5,
  y: 7.5,
}

/**
 * Example: November 21, 2022
 */
export const SINGLE_POINT_DATE_LABEL_FORMAT = "%B %e, %Y"

// Highcharts dateFormat docs: https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat
// OpenSea Content Design-System: https://www.notion.so/opensea/Date-and-time-0e06de26d28b4200b1e430de74ebaad9
export const DEFAULT_DATE_TIME_LABEL_FORMATS = {
  millisecond: "%l:%M:%S.%L %p",
  second: "%l:%M:%S %p",
  minute: "%l:%M %p",
  hour: "%l %p",
  day: "%b %e",
  week: "%b %e",
  month: "%b %y",
}
