import React from "react"
import { Flex, Icon, Media, RadioGroup, Text } from "@opensea/ui-kit"
import { noop } from "lodash"
import styled from "styled-components"
import {
  MOBILE_DRAWER_TRANSITION_DURATION,
  MOBILE_DRAWER_TRANSITION_TIMING_FUNCTION,
} from "@/components/layout/AccountOrCollectionPage/constants"
import { SearchOption } from "@/components/search/SearchOption.react"
import { Z_INDEX } from "@/constants/zIndex"
import { Button } from "@/design-system/Button"
import { Drawer } from "@/design-system/Drawer"
import { IconButton } from "@/design-system/IconButton"
import { Select, SelectOption, SelectProps } from "@/design-system/Select"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"

export type TimeRangeSelectorProps<T extends string> = Omit<
  SelectProps<T, SelectOption<T>>,
  "clearable" | "readOnly" | "onSelect" | "value"
> & {
  timeRange: T | undefined
  setTimeRange: (timeRange: T) => unknown
}

export const TimeRangeSelector = <T extends string>({
  timeRange,
  setTimeRange,
  options,
  ...restProps
}: TimeRangeSelectorProps<T>) => {
  const t = useTranslate("components")
  const { visibleAnnouncementBannerHeight } = useAnnouncementBanner()

  const {
    isOpen: isDrawerOpen,
    open: openDrawer,
    close: closeDrawer,
  } = useIsOpen()

  return (
    <>
      <Media greaterThanOrEqual="lg">
        <Select
          clearable={false}
          options={options}
          readOnly
          value={timeRange}
          onSelect={option => option && setTimeRange(option.value)}
          {...restProps}
        />
      </Media>
      <Media lessThan="lg">
        <Button
          aria-label={t("timeRangeSelector.range", "Time range")}
          icon="schedule"
          variant="secondary"
          onClick={event => {
            event.preventDefault()
            openDrawer()
          }}
        />
        <CustomDrawer
          anchorSide="bottom"
          bannerHeight={visibleAnnouncementBannerHeight}
          data-testid="time-range-selector-drawer"
          isOpen={isDrawerOpen}
          isVisible
          style={{ zIndex: Z_INDEX.NAVBAR }}
          transitionDuration={MOBILE_DRAWER_TRANSITION_DURATION}
          transitionTimingFunction={MOBILE_DRAWER_TRANSITION_TIMING_FUNCTION}
          onClickAway={noop}
        >
          <Flex className="flex-col p-4">
            <Flex asChild className="relative mb-4 items-center justify-center">
              <header>
                <Text.Heading size="small">
                  {t("timeRangeSelector.timeRange", "Time range")}
                </Text.Heading>

                <Flex className="absolute right-0">
                  <IconButton
                    onClick={event => {
                      event.preventDefault()
                      closeDrawer()
                    }}
                  >
                    <Icon size={24} value="close" />
                  </IconButton>
                </Flex>
              </header>
            </Flex>
            <RadioGroup value={timeRange} onValueChange={setTimeRange}>
              {options.map(option => {
                const { label, value } = option

                return (
                  <SearchOption
                    className="bg-elevation-2"
                    id={value}
                    isSelected={timeRange === value}
                    key={value}
                    label={label}
                    name="time-range-dropdown"
                    type="radio"
                  />
                )
              })}
            </RadioGroup>
          </Flex>
          <Flex className="sticky bottom-0 z-[1] mt-auto w-full border-t border-t-level-2 bg-base-1 p-4">
            <Button
              className="w-full"
              onClick={event => {
                event.preventDefault()
                closeDrawer()
              }}
            >
              {t("timeRangeSelector.done", "Done")}
            </Button>
          </Flex>
        </CustomDrawer>
      </Media>
    </>
  )
}

// TODO: Consolidate with AssetSearchSortDropdown
const CustomDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
