import { useMemo } from "react"
import Highcharts from "highcharts"
import HighchartsBoost from "highcharts/modules/boost"
import {
  HighchartsReact,
  HighchartsReactRefObject as RefObject,
} from "highcharts-react-official"
import { IS_SERVER } from "@/constants/environment"
import { useTranslate } from "@/hooks/useTranslate"

// Only apply modules on client side where highcharts is initialized
if (!IS_SERVER) {
  HighchartsBoost(Highcharts)
}

export type { RefObject }
export { HighchartsReact }

export const useHighcharts = () => {
  const t = useTranslate("designSystem")

  const HighchartsWithTranslations = useMemo(() => {
    const months = [
      t("highcharts.months.jan", "January"),
      t("highcharts.months.feb", "February"),
      t("highcharts.months.mar", "March"),
      t("highcharts.months.apr", "April"),
      t("highcharts.months.may", "May"),
      t("highcharts.months.jun", "June"),
      t("highcharts.months.jul", "July"),
      t("highcharts.months.aug", "August"),
      t("highcharts.months.sep", "September"),
      t("highcharts.months.oct", "October"),
      t("highcharts.months.nov", "November"),
      t("highcharts.months.dec", "December"),
    ]

    const shortMonths = [
      t("highcharts.shortMonths.jan", "Jan"),
      t("highcharts.shortMonths.feb", "Feb"),
      t("highcharts.shortMonths.mar", "Mar"),
      t("highcharts.shortMonths.apr", "Apr"),
      t("highcharts.shortMonths.may", "May"),
      t("highcharts.shortMonths.jun", "Jun"),
      t("highcharts.shortMonths.jul", "Jul"),
      t("highcharts.shortMonths.aug", "Aug"),
      t("highcharts.shortMonths.sep", "Sep"),
      t("highcharts.shortMonths.oct", "Oct"),
      t("highcharts.shortMonths.nov", "Nov"),
      t("highcharts.shortMonths.dec", "Dec"),
    ]

    const weekdays = [
      t("highcharts.weekdays.sun", "Sunday"),
      t("highcharts.weekdays.mon", "Monday"),
      t("highcharts.weekdays.tue", "Tuesday"),
      t("highcharts.weekdays.wed", "Wednesday"),
      t("highcharts.weekdays.thu", "Thursday"),
      t("highcharts.weekdays.fri", "Friday"),
      t("highcharts.weekdays.sat", "Saturday"),
    ]

    Highcharts.setOptions({
      lang: {
        months,
        shortMonths,
        weekdays,
      },
    })

    return Highcharts
  }, [t])

  return { Highcharts: HighchartsWithTranslations }
}
