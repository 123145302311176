import React from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { ErrorFetchingData } from "@/components/common/ErrorFetchingData.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import {
  CollectionPriceHistoryChartQuery,
  CollectionPriceHistoryChartQuery$variables,
} from "@/lib/graphql/__generated__/CollectionPriceHistoryChartQuery.graphql"
import {
  PriceHistoryChart,
  PriceHistoryChartProps,
  PriceHistoryChartSkeleton,
} from "./PriceHistoryChart.react"
import { usePriceHistoryChartAdjustedStartTime } from "./usePriceHistoryChartAdjustedStartTime"

type LazyCollectionPriceHistoryChartProps = Omit<
  CollectionPriceHistoryChartQuery$variables,
  "startDate"
> &
  Omit<PriceHistoryChartProps, "data">

const LazyCollectionPriceHistoryChart = ({
  collectionSlug,
  startDate,
  bucketSize = "DAY",
  stringTraits,
  showNoData,
  ...restProps
}: LazyCollectionPriceHistoryChartProps) => {
  const adjustedStartDate = usePriceHistoryChartAdjustedStartTime({
    startDate,
    bucketSize,
  })

  const data = useLazyLoadQuery<CollectionPriceHistoryChartQuery>(
    graphql`
      query CollectionPriceHistoryChartQuery(
        $collectionSlug: CollectionSlug!
        $startDate: DateTime
        $stringTraits: [TraitInputType!]
        $bucketSize: BucketSize = DAY
      ) {
        tradeHistory(
          minTime: $startDate
          collection: $collectionSlug
          bucketSize: $bucketSize
          stringTraits: $stringTraits
        ) {
          ...PriceHistoryChart_data
        }
      }
    `,
    {
      collectionSlug,
      startDate: adjustedStartDate?.toISOString(),
      bucketSize,
      stringTraits,
    },
  )

  return (
    <PriceHistoryChart
      bucketSize={bucketSize}
      data={data.tradeHistory}
      showNoData={showNoData}
      startDate={adjustedStartDate}
      {...restProps}
    />
  )
}

export const CollectionPriceHistoryChart = (
  props: LazyCollectionPriceHistoryChartProps,
) => {
  return (
    <ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorFetchingData
          height={props.height || undefined}
          resetError={resetError}
        />
      )}
    >
      <SsrSuspense fallback={<PriceHistoryChartSkeleton {...props} />}>
        <LazyCollectionPriceHistoryChart {...props} />
      </SsrSuspense>
    </ErrorBoundary>
  )
}
