/**
 * @generated SignedSource<<a6a7f4b6b5bba5ecb2a108921f82c8af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BucketSize = "DAY" | "HOUR" | "MINUTE" | "MONTH" | "WEEK" | "%future added value";
export type TraitInputType = {
  name: string;
  values: ReadonlyArray<string>;
};
export type CollectionPriceHistoryChartQuery$variables = {
  bucketSize?: BucketSize | null;
  collectionSlug: string;
  startDate?: string | null;
  stringTraits?: ReadonlyArray<TraitInputType> | null;
};
export type CollectionPriceHistoryChartQuery$data = {
  readonly tradeHistory: {
    readonly " $fragmentSpreads": FragmentRefs<"PriceHistoryChart_data">;
  };
};
export type CollectionPriceHistoryChartQuery = {
  response: CollectionPriceHistoryChartQuery$data;
  variables: CollectionPriceHistoryChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "DAY",
  "kind": "LocalArgument",
  "name": "bucketSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionSlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stringTraits"
},
v4 = [
  {
    "kind": "Variable",
    "name": "bucketSize",
    "variableName": "bucketSize"
  },
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collectionSlug"
  },
  {
    "kind": "Variable",
    "name": "minTime",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "stringTraits",
    "variableName": "stringTraits"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionPriceHistoryChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TradeHistoryType",
        "kind": "LinkedField",
        "name": "tradeHistory",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceHistoryChart_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CollectionPriceHistoryChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TradeHistoryType",
        "kind": "LinkedField",
        "name": "tradeHistory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeHistoryDataPoint",
            "kind": "LinkedField",
            "name": "results",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bucketStart",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetQuantityType",
                "kind": "LinkedField",
                "name": "volume",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetType",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "decimals",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b700b373a14b6b1a6feb85f35e1b2233",
    "id": null,
    "metadata": {},
    "name": "CollectionPriceHistoryChartQuery",
    "operationKind": "query",
    "text": "query CollectionPriceHistoryChartQuery(\n  $collectionSlug: CollectionSlug!\n  $startDate: DateTime\n  $stringTraits: [TraitInputType!]\n  $bucketSize: BucketSize = DAY\n) {\n  tradeHistory(minTime: $startDate, collection: $collectionSlug, bucketSize: $bucketSize, stringTraits: $stringTraits) {\n    ...PriceHistoryChart_data\n  }\n}\n\nfragment PriceHistoryChart_data on TradeHistoryType {\n  results {\n    bucketStart\n    quantity\n    volume {\n      asset {\n        decimals\n        id\n      }\n      quantity\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2798220c0a37f7ea223370539a9f893";

export default node;
