import React from "react"
import { Flex, Skeleton } from "@opensea/ui-kit"
import { random, range } from "lodash"
import { Block, BlockProps } from "@/design-system/Block"

const NUM_X_AXIS_LINES = 5

// Returns a random height percentage value between 35% and 85%
const getRandomHeight = () => {
  return `${random(35, 85)}%`
}

export type ColumnChartSkeletonProps = {
  numColumns: number
  height: BlockProps["height"]
  columnWidth?: string
  children?: React.ReactNode
}

export const ColumnChartSkeleton = ({
  numColumns,
  height,
  columnWidth = "32px",
  children,
}: ColumnChartSkeletonProps) => {
  return (
    <Block className="relative" height={height}>
      <Flex className="absolute left-0 top-0 h-full w-full flex-row items-end justify-around">
        {range(numColumns).map(index => (
          <Skeleton.Block
            className="rounded"
            key={index}
            style={{ width: columnWidth, height: getRandomHeight() }}
          />
        ))}
      </Flex>
      <Flex className="absolute left-0 top-0 h-full w-full flex-col justify-between">
        {range(NUM_X_AXIS_LINES).map(index => (
          <Skeleton.Line className="h-px w-full" key={index} />
        ))}
      </Flex>
      {children}
    </Block>
  )
}
