import { BucketSize } from "@/lib/graphql/__generated__/CollectionPriceHistoryChartQuery.graphql"

export const getBucketSize = <T extends string>(timeRange: T): BucketSize => {
  // Use minute bucket size if range is less than or equal to 6 hours
  if (Number(timeRange) <= 6) {
    return "MINUTE"
  }

  // Use hour bucket size if range is less than or equal to 3 day
  if (Number(timeRange) <= 72) {
    return "HOUR"
  }

  // Use day bucket size if range is less than or equal to 30 days
  if (Number(timeRange) <= 720) {
    return "DAY"
  }

  return "WEEK"
}
