import React, { useEffect } from "react"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useChart } from "./ChartPanel"

export type RenderTooltipFn<T> = (
  d: T,
  points: Highcharts.Point[],
) => React.ReactNode

// Extra y-offset required to align tooltip better to different types of highchart points
const POINT_TYPE_TO_Y_OFFSET = {
  marker: 7,
  column: -2,
} as const

type PointType = keyof typeof POINT_TYPE_TO_Y_OFFSET

type ChartTooltipProps = Pick<TooltipProps, "content"> & {
  pointType: PointType
}

export const ChartTooltip = ({ content, pointType }: ChartTooltipProps) => {
  const { isPanning, setHoverPoints, hoverPoints } = useChart()
  const firstHoverPoint = hoverPoints[0]

  useEffect(() => {
    const close = () => setHoverPoints([])
    window.addEventListener("scroll", close)
    return () => {
      window.removeEventListener("scroll", close)
    }
  }, [setHoverPoints])

  if (isPanning) {
    return <></>
  }

  return (
    <Tooltip
      className="rounded-2xl"
      content={content}
      contentPadding="12px"
      touch
      variant="translucent"
      visible
    >
      <Block
        className="absolute"
        style={{
          left:
            firstHoverPoint.series.chart.plotLeft +
            (firstHoverPoint.plotX ?? 0),
          top:
            firstHoverPoint.series.chart.plotTop +
            firstHoverPoint.series.yAxis.toPixels(
              firstHoverPoint.total ?? firstHoverPoint.y ?? 0,
              true,
            ) -
            POINT_TYPE_TO_Y_OFFSET[pointType],
        }}
      />
    </Tooltip>
  )
}
