import { startOfHour, startOfMinute, subHours } from "date-fns"
import { SelectOption } from "@/design-system/Select"
import { bn } from "@/lib/helpers/numberUtils"

/**
 * Selects the largest time range option less than or equal to `max`.
 * Returns the last provided option if `max` is undefined.
 */
export const getDefaultTimeRangeOption = <T extends string>(
  options: readonly SelectOption<T>[],
  max: number | undefined,
): SelectOption<T> | undefined => {
  if (max === undefined) {
    return options.length > 0 ? options[options.length - 1] : undefined
  }

  let defaultOption: SelectOption<T> | undefined = undefined
  options.forEach(option => {
    const optionValue = Number(option.value)
    const currentDefaultOptionValue =
      defaultOption === undefined
        ? Number.NEGATIVE_INFINITY
        : Number(defaultOption.value)

    if (optionValue <= max && optionValue > currentDefaultOptionValue) {
      defaultOption = option
    }
  })

  return defaultOption
}

export const getStartDateFromTimeRange = <T extends string | undefined>(
  timeRange: T,
) => {
  if (timeRange === undefined || bn(timeRange).isNaN()) {
    return undefined
  }
  // Round end time (and start time) to enable caching
  // If time range is less than 24 hours then use start of minute as end time
  const endDate =
    Number(timeRange) < 24 ? startOfMinute(new Date()) : startOfHour(new Date())
  return subHours(endDate, Number(timeRange))
}
