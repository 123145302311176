import React, { useEffect, useMemo, useRef } from "react"
import { useIsLessThanBreakpoint } from "@opensea/ui-kit"
import { lighten } from "polished"
import { useTheme } from "@/design-system/Context"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useMouseUp } from "@/hooks/useMouseUp"
import THEMES from "@/styles/themes"
import { ChartContainer } from "../ChartContainer"
import { useChart } from "../ChartPanel"
import { ChartTooltip, RenderTooltipFn } from "../ChartTooltip.react"
import { ColumnChartSkeleton } from "../ColumnChartSkeleton.react"
import { HighchartsReact, useHighcharts, RefObject } from "../highcharts"
import {
  DEFAULT_HIGHCHART_OPTIONS,
  createChartOptions,
  createTooltipOptions,
  createSeriesOptions,
  createXAxisOptions,
  createYAxisOptions,
  SINGLE_POINT_DATE_LABEL_FORMAT,
  DEFAULT_DATE_TIME_LABEL_FORMATS,
} from "../utils"
import { getPointIndex } from "../utils/points"
import { AxisTitleProps, RangeProps } from "../utils/types"

export type AccessorProps<T> = {
  getDate: (d: T) => Date
  getValue: (d: T) => number
  getColumnValue?: (d: T) => number
}

export type TimeSeriesChartProps<T> = AxisTitleProps &
  AccessorProps<T> &
  RangeProps &
  Pick<Highcharts.ChartOptions, "height" | "width"> & {
    /**
     * Points in data must be sorted in chronological order for zooming and panning to work.
     */
    data: readonly T[]
    renderTooltip?: RenderTooltipFn<T>
    interactive?: boolean
    lineType?: "line" | "spline" | "area"
    lineWidth?: number
    markerRadius?: number
    hideAxes?: boolean
    columnAxisTitle?: string
    isLastDataPointComplete?: boolean
    singlePointDateLabelFormat?: string
    overrides?: {
      tooltip?: Highcharts.TooltipOptions
    }
  }

const TimeSeriesChartBase = <T,>({
  data,
  getColumnValue,
  getDate,
  getValue,
  height,
  width,
  renderTooltip,
  interactive = false,
  lineType = "spline",
  lineWidth = 2,
  markerRadius = 4,
  hideAxes = false,
  xAxisTitle,
  yAxisTitle,
  columnAxisTitle,
  isLastDataPointComplete = true,
  hideAxisTitlesBreakpoint = "sm",
  rangeStart,
  rangeEnd,
  singlePointDateLabelFormat = SINGLE_POINT_DATE_LABEL_FORMAT,
  overrides = { tooltip: {} },
}: TimeSeriesChartProps<T>) => {
  const { theme } = useTheme()
  const {
    setChart,
    setIsZoomed,
    setXAxisRange,
    setIsPanning,
    hoverPoints,
    setHoverPoints,
  } = useChart()
  const { Highcharts } = useHighcharts()
  const chartComponentRef = useRef<RefObject>(null)
  const hideAxisTitle = useIsLessThanBreakpoint(hideAxisTitlesBreakpoint)

  useMountEffect(() => {
    return () => setChart(undefined)
  })

  const overridesRef = useRef(overrides)
  useEffect(() => {
    overridesRef.current = overrides
  }, [overrides])

  const rangeStartTime = rangeStart?.getTime()
  const rangeEndTime = rangeEnd?.getTime()

  useEffect(() => {
    setXAxisRange([rangeStartTime, rangeEndTime])
    chartComponentRef.current?.chart.xAxis[0].setExtremes(
      rangeStartTime,
      rangeEndTime,
    )
  }, [setXAxisRange, rangeStartTime, rangeEndTime])

  useEffect(() => {
    setHoverPoints([])
  }, [data, setHoverPoints])

  const mouseUpRef = useMouseUp(() => setIsPanning(false))

  const series = useMemo(() => {
    const series: Highcharts.SeriesOptionsType[] = []

    if (getColumnValue) {
      // Add column series first so it always has index of 0
      series.push(
        createSeriesOptions(theme, {
          type: "column",
          data: data.map(dp => [getDate(dp).getTime(), getColumnValue(dp)]),
          color: THEMES[theme].colors.fog,
          borderRadius: 4,
          states: {
            hover: {
              color: THEMES[theme].colors.mist,
            },
          },
          maxPointWidth: 32,
        }),
      )
    }

    if (lineType === "area") {
      series.push(
        createSeriesOptions(theme, {
          type: "area",
          data: data.map(dp => [getDate(dp).getTime(), getValue(dp)]),
          yAxis: 1,
          marker: {
            enabled: false,
            fillColor: THEMES[theme].colors.primary,
            radius: markerRadius,
          },
          lineColor: THEMES[theme].colors.primary,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, lighten(0.35, THEMES[theme].colors.primary)], // start
              [1, "#ffffff"], // end
            ],
          },
          lineWidth,
        }),
      )
    } else {
      series.push(
        createSeriesOptions(theme, {
          type: lineType,
          data: data.map(dp => [getDate(dp).getTime(), getValue(dp)]),
          yAxis: 1,
          marker: {
            enabled: false,
            fillColor: THEMES[theme].colors.primary,
            radius: markerRadius,
          },
          lineWidth,
          color: {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
              [0, THEMES[theme].colors.aqua], // start
              [1, "#5D32E9"], // end
            ],
          },
          // Sets dotted line to last data point if incomplete
          zoneAxis: "x",
          zones: [
            {
              value:
                !isLastDataPointComplete && data.length >= 2
                  ? getDate(data[data.length - 2]).getTime() // x value of last complete data point
                  : undefined,
            },
            {
              dashStyle: "Dot",
            },
          ],
        }),
      )
    }

    return series
  }, [
    data,
    getColumnValue,
    getDate,
    getValue,
    isLastDataPointComplete,
    lineType,
    lineWidth,
    markerRadius,
    theme,
  ])

  const options: Highcharts.Options = useMemo(() => {
    return {
      ...DEFAULT_HIGHCHART_OPTIONS,
      chart: createChartOptions(theme, {
        height,
        width,
        interactive,
        spacing: hideAxes
          ? [0, 0, 0, 0]
          : Highcharts.defaultOptions.chart?.spacing,
        events: {
          load() {
            // Set linear gradient based on pixel width of the chart on load.
            // This fixes colored lines on constant series not displaying.
            const color: Highcharts.GradientColorObject = {
              // Casting since highcharts library is missing this type and it
              // allows us to set gradient based on pixel width.
              // https://www.highcharts.com/docs/chart-design-and-style/colors#linear-gradients
              linearGradient: [
                0,
                0,
                this.plotWidth,
                0,
              ] as unknown as Highcharts.LinearGradientColorObject,
              stops: [
                [0, THEMES[theme].colors.aqua], // start
                [1, "#5D32E9"], // end
              ],
            }
            this.update({
              plotOptions: {
                [lineType]: { color },
              },
            })
            this.xAxis[0].setExtremes(rangeStartTime, rangeEndTime)
            setChart(this)
          },
        },
      }),
      yAxis: [
        createYAxisOptions(theme, {
          visible: !hideAxes,
          axisTitle: hideAxisTitle ? undefined : columnAxisTitle,
        }),
        createYAxisOptions(theme, {
          opposite: true,
          visible: !hideAxes,
          axisTitle: hideAxisTitle ? undefined : yAxisTitle,
        }),
      ],
      xAxis: createXAxisOptions(theme, {
        type: "datetime",
        visible: !hideAxes,
        axisTitle: hideAxisTitle ? undefined : xAxisTitle,
        dateTimeLabelFormats:
          data.length === 1
            ? {
                ...DEFAULT_DATE_TIME_LABEL_FORMATS,
                // Highcharts uses millisecond date time label format if there is only one point
                millisecond: singlePointDateLabelFormat,
              }
            : DEFAULT_DATE_TIME_LABEL_FORMATS,
        events: {
          afterSetExtremes(event) {
            setIsZoomed(
              event.userMin !== rangeStartTime ||
                event.userMax !== rangeEndTime,
            )
          },
          setExtremes(event) {
            if (event.trigger === "pan") {
              setIsPanning(true)
            }
          },
        },
      }),
      series,
      tooltip: createTooltipOptions(theme, {
        formatter() {
          if (this.points) {
            // If there is a column series, it is always at index 0
            const tooltipSeriesIndex = 0 // Use column series if it exists
            const tooltipSeriesPoint = this.points[tooltipSeriesIndex].point
            setHoverPoints([tooltipSeriesPoint])
          }
          // we are not showing the default tooltip anyways so return empty string
          return ""
        },
        ...overridesRef.current.tooltip,
      }),
    }
  }, [
    Highcharts.defaultOptions.chart?.spacing,
    columnAxisTitle,
    data.length,
    height,
    hideAxes,
    hideAxisTitle,
    interactive,
    lineType,
    rangeEndTime,
    rangeStartTime,
    series,
    setChart,
    setHoverPoints,
    setIsPanning,
    setIsZoomed,
    singlePointDateLabelFormat,
    theme,
    width,
    xAxisTitle,
    yAxisTitle,
  ])

  return (
    <ChartContainer
      height={height}
      ref={mouseUpRef}
      width={width}
      onMouseLeave={() => {
        setHoverPoints([])
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
      {hoverPoints.length > 0 && renderTooltip && (
        <ChartTooltip
          content={renderTooltip(data[getPointIndex(hoverPoints[0])], [
            hoverPoints[0],
          ])}
          pointType="marker"
        />
      )}
    </ChartContainer>
  )
}

export const TimeSeriesChart = Object.assign(TimeSeriesChartBase, {
  Skeleton: ColumnChartSkeleton,
})
