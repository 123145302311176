import { useState } from "react"
import { useUpdateEffect } from "react-use"
import { SelectOption } from "@/design-system/Select"
import { getDefaultTimeRangeOption } from "./utils"

type UseTimeRangeStateProps<T extends string> = {
  options: readonly SelectOption<T>[]
  defaultMax?: number // Max in months that can be selected as default
}

export const useTimeRangeState = <T extends string>({
  options,
  defaultMax,
}: UseTimeRangeStateProps<T>) => {
  const [timeRange, setTimeRange] = useState<T | undefined>(() => {
    const defaultOption = getDefaultTimeRangeOption(options, defaultMax)
    return defaultOption?.value
  })

  // Set default option when options change
  useUpdateEffect(() => {
    const defaultOption = getDefaultTimeRangeOption(options, defaultMax)
    setTimeRange(defaultOption?.value)
  }, [JSON.stringify(options), defaultMax])

  return [timeRange, setTimeRange] as const
}
